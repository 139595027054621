import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/configure',
  component: Layout,
  name: 'Configure',
  redirect: '/configure/index',
  meta: {
    title: '系统配置',
    icon: 'tabler:settings-cog',
    sort: 13,
  },
  children: [
    {
      path: 'index',
      name: 'ConfigureList',
      component: () => import('@/views/configure/index.vue'),
      meta: {
        title: '系统配置',
        menu: false,
        breadcrumb: false,
        activeMenu: '/configure',
      },
    },
    {
      path: 'management',
      name: 'SensitiveWordManagement',
      component: () => import('@/views/configure/sensitiveWordManagement.vue'),
      meta: {
        title: '敏感词管理',
        menu: false,
        breadcrumb: false,
        activeMenu: '/configure',
      },
    },
  ],
}

export default routes
