import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/timeSquare',
  component: Layout,
  redirect: '/timeSquare/dynamics',
  name: 'TimeSquare',
  meta: {
    title: '时刻广场',
    icon: 'i-ri:database-2-line',
    sort: 2,
  },
  children: [
    {
      path: 'dynamics',
      name: 'TimeSquaredDynamics',
      component: () => import('@/views/timeSquare/index.vue'),
      meta: {
        title: '动态管理',
        menu: false,
        breadcrumb: false,
        activeMenu: '/recharge',
      },
    },
    {
      path: 'comments',
      name: 'CommentManagement',
      component: () => import('@/views/timeSquare/commentManagement/index.vue'),
      meta: {
        title: '评论管理',
        menu: false,
        breadcrumb: false,
        activeMenu: '/recharge',
      },
    },
  ],
}

export default routes
