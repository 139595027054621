import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/statisticsPlatform',
  component: Layout,
  name: 'statisticsPlatform',
  redirect: '/statisticsPlatform/index',
  meta: {
    title: '运营数据',
    icon: 'material-symbols:aspect-ratio-sharp',
    sort: 5,
  },
  children: [
    {
      path: 'index',
      name: 'StatisticsPlatform',
      component: () => import('@/views/statisticsPlatform/index.vue'),
      meta: {
        title: '运营数据',
        menu: false,
        breadcrumb: false,
        activeMenu: '/statisticsPlatform',
      },
    },
  ],
}

export default routes
