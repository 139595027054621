import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/groupChat',
  component: Layout,
  name: 'groupChat',
  redirect: '/groupChat/manager',
  meta: {
    title: '群聊发布',
    icon: 'i-tdesign:usergroup-add',
    sort: 5,
  },
  children: [
    {
      path: 'manager',
      name: 'GroupChatManager',
      component: () => import('@/views/groupChat/index.vue'),
      meta: {
        title: '群聊发布',
        menu: false,
        breadcrumb: false,
        activeMenu: '/groupChat',
      },
    },
    {
      path: 'barrage',
      name: 'BarrageConfig',
      component: () => import('@/views/barrageConfig/index.vue'),
      meta: {
        title: '群聊弹幕配置',
        menu: false,
        breadcrumb: false,
      },
    },
    {
      path: 'groupFreeze',
      name: 'GroupFreeze',
      component: () => import('@/views/groupFreeze/index.vue'),
      meta: {
        title: '群聊收益冻结',
        menu: false,
        breadcrumb: false,
      },
    },
  ],
}

export default routes
